<template>
  <div class="dashboard-content" data-app>
    <!-- Filters -->
    <div class="filters">
      <v-row style="width: 100%">
        <h4 style="margin: 0; align-self: center">
          {{ $t('Views.AdminDashboard.filterText') }}
        </h4>
        <v-col cols="12" lg="3" md="4" sm="6">
          <multiselect
            track-by="id"
            label="name"
            deselect-label=""
            select-label=""
            :placeholder="$t('Views.AdminDashboard.filterPlaceholder')"
            v-model="filters.countryId"
            :options="countries"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="false"
          ></multiselect>
        </v-col>
        <v-col>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="#0c2c7c"
                style="align-self: center"
                dark
                class="px-1 py-1"
                @click.prevent="
                  filterActivated = true
                  getStats()
                "
                ><v-icon dark> mdi-filter </v-icon>
              </v-btn>
            </template>
            <span>Filtrar</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </div>
    <!-- Content -->
    <div class="row">
      <!-- Item -->
      <div class="col-lg-3 col-md-6 px-1">
        <div class="card mb-4">
          <div class="card-body text-center">
            <i class="i-Add-User"></i>
            <div class="py-1">
              <h2 class="text-primary mb-2">
                {{ stats.companies ? stats.companies : '0' }}
              </h2>
              <p class="mt-2 mb-0">
                {{ $t('Views.AdminDashboard.associations') }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- Item -->
      <div class="col-lg-3 col-md-6 px-1">
        <div class="card mb-4">
          <div class="card-body text-center">
            <i class="i-Add-User"></i>
            <div class="py-1">
              <h2 class="text-primary mb-2">{{ stats.users ? stats.users : '0' }}</h2>
              <p class="mt-2 mb-0">{{ $t('Views.AdminDashboard.users') }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Item -->
      <div class="col-lg-3 col-md-6 px-1">
        <div class="card mb-4">
          <div class="card-body text-center">
            <i class="i-Add-User"></i>
            <div class="py-1">
              <h2 class="text-primary mb-2">
                {{ stats.countries ? stats.countries : '0' }}
              </h2>
              <p class="mt-2 mb-0">
                {{ $t('Views.AdminDashboard.countries') }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- Item -->
      <div class="col-lg-3 col-md-6 px-1">
        <div class="card mb-4">
          <div class="card-body text-center">
            <i class="i-Add-User"></i>
            <div class="py-1">
              <h2 class="text-primary mb-2">
                {{ stats.publications ? stats.publications : '0' }}
              </h2>
              <p class="mt-2 mb-0">
                {{ $t('Views.AdminDashboard.publications') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
 
  export default {
 
    data() {
      return {
        loading: true,
        userActivities: [],
        roles: [],
        users: [],
        advertisements: [],
        stats: {},
        filterActivated: false,
        countries: [{ id: null, name: 'Todos' }],
        filters: {
          countryId: null,
        },
      }
    },
    created() {
      this.getStats()
    },
    methods: {
      checkPermissions() {
        if (this.$session.get('roles')) {
          this.$session.get('roles').forEach((role) => {
            this.roles.push(role.name)
          })

          if (!this.roles.includes('admin', 'super_admin')) {
            this.$router.push('/admin/profile')
          }
        } else {
          this.$router.push('/')
        }
      },
      async getStats() {
        this.loading = true
        let countryString = ''
        if (this.filterActivated) {
          if (this.filters.countryId && this.filters.countryId.id) {
            countryString = 'country_id=' + this.filters.countryId.id
          }
        }
        axios.get('stats-management/general-stats?' + countryString).then((response) => {
          this.stats = response.data
          this.loading = false
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  @import '@/assets/scss/cruds/_crudStyles.scss';
  .card-body {
    flex: 1 1 auto;
    padding: 5px;
  }
</style>
